










































import RefreshChats from '@/components/RefreshChats.vue'
import Statistics from '@/includes/logic/Statistics'
import Api from '../includes/logic/Api'
import IfChatsEmpty from '@/components/IfChatsEmpty.vue'
import makeId from '@/assets/utils/makeId'
import ChatCard from '@/components/vu-components/ChatCard.vue'
import { errorNotification } from '@/includes/NotificationService'

import moment from 'moment'
import { Component, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    RefreshChats,
    IfChatsEmpty,
    ChatCard
  },
  data() {
    return {
      makeId
    }
  }
})
export default class Chats extends Vue {
  haveChats = true

  chatsStat:any = []

  statisticsLoaded = false

  @Watch('$store.state.chatState.chatsInfo.chats')
  onChatsChange(): void {
    const chats = this.chatsInfo
    let haveChats = false

    if (chats !== undefined) {
      chats.map((chat:any) => {
        if (chat.hidden === false) haveChats = true
      })
    }

    this.haveChats = haveChats
  }

  get filterChatsByMembers() {
    if (this.chatsInfo !== undefined) {
      return this.chatsInfo
        .slice()
        .filter((chat:any) => chat.member_count !== null)
        .filter((chat:any) => !chat.hidden)
        .sort((a:any, b:any) => b.member_count - a.member_count)
        .concat(this.chatsInfo
          .slice()
          .filter((chat:any) => chat.member_count === null)
          .sort((a:any, b:any) => Math.abs(b.chat_id) - Math.abs(a.chat_id)))
    }
  }

  get chatsInfo():any {
    return cloneDeep(this.$store.state.chatState.chatsInfo.chats)
  }

  prepareChatsStatistics(data:any):any {
    const resObject:any = {}
    const timeTicks:Array<string> = []
    const dataTicks:Array<number> = []
    const from = moment().add(-7, 'days').startOf('day')
    const to = moment()

    while (!from.isAfter(to)) {
      timeTicks.push(from.format('YYYY-MM-DD HH:mm:ss'))
      dataTicks.push(0)
      from.add(1, 'day')
    }

    data.map((chatStatistics:any) => {
      const usersSeries = {
        name: this.$t('chat_card_statistics_users'),
        data: cloneDeep(dataTicks),
      }
      const msgsSeries = {
        name: this.$t('chat_card_statistics_msgs'),
        data: cloneDeep(dataTicks),
      }

      chatStatistics.activity.map((item:any) => {
        if (timeTicks.includes(item.time)) {
          const index = timeTicks.indexOf(item.time)
          if (item.users) {
            usersSeries.data[index] = item.users
          }
          if (item.msgs) {
            msgsSeries.data[index] = item.msgs
          }
        }
      })

      resObject[chatStatistics.chat_id] = {
        leave: chatStatistics.leave,
        new: chatStatistics.new,
        total: chatStatistics.total,
        series: [ usersSeries, msgsSeries ],
      }
    })

    return resObject
  }

  created() {
  }

  destroyed() {
    this.$baseTemplate.saveButton.show()
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()

    const chatsInfoString = localStorage.getItem('chats_info_stat')
    let chat_info_stat:any;
    if (chatsInfoString) {
      chat_info_stat = JSON.parse(chatsInfoString)
    }
    if (chat_info_stat && ((Date.now() - chat_info_stat.time) < 1000 * 3600) && chat_info_stat.user === this.$store.state.userState.user.userId) {
      this.chatsStat = chat_info_stat.data
      this.statisticsLoaded = true
    } else {
      const statistics = new Statistics(Api.getMethodUrl('tg', 'getchatsinfostat'))
      statistics.getChatsStatistics()
        .then(res => {
          this.chatsStat = this.prepareChatsStatistics(res.data.chats_stat)
          localStorage.setItem('chats_info_stat', JSON.stringify({
            data: this.chatsStat,
            time: Date.now(),
            user: this.$store.state.userState.user.userId
          }))

          this.statisticsLoaded = true
        })
    }
  }
}
